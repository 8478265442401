<div class="bread-wrap" *ngIf="!isResetPassword">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink=""><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">Change Password</li>
        </ol>
        <h2 class="heading-card">Change Password</h2>
    </nav>
</div>
<div class="logo-wrap py-4 text-center" *ngIf="isResetPassword">
    <img class="logo" src="../../../assets/img/Logo.png" alt="Logo">
</div>
<div class="seprator" *ngIf="isResetPassword"></div>
<div class="login-form mx-auto">
    <h4 class="mb-3 text-center">{{isResetPassword&&!isSetPassword ? 'Reset' : (isSetPassword ? 'Set' : 'Change')}} Password</h4>
    <div class="border"></div>
    <div class="d-flex flex-column alert" *ngIf="message.length">
        <div class="text-center text-danger" >
            <ng-container>{{message}}</ng-container>
        </div>
    </div>
    <div class="text-center text-danger" *ngIf="isResetPassword && isLinkVerifiedByAPI && isLinkExpired">The Link is no
        longer valid</div>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordForm.valid && updatePassword()"
        *ngIf="!isResetPassword || (isLinkVerifiedByAPI && isResetPassword && !isLinkExpired)">
        <div class="form-group" *ngIf="!isResetPassword">
            <label for="oldPassword">Old Password*</label>
            <div class="input-group">
                <input [type]="isOldTextTypeInput ? 'text' : 'password'" class="form-control py-3" id="oldPassword"
                    formControlName="oldPassword"
                (keyup)="clearErrorMessage()" placeholder="Old Password">
                <div class="input-group-append">
                    <a (click)="isOldTextTypeInput = !isOldTextTypeInput" class="input-group-text" id="basic-addon2"
                        role="button">{{isOldTextTypeInput ? 'Hide' :
                        'show'}}</a>
                </div>
            </div>
           
            <div *ngIf="resetPasswordForm.controls['oldPassword'].invalid && (resetPasswordForm.controls['oldPassword'].dirty || resetPasswordForm.controls['oldPassword'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="resetPasswordForm.controls['oldPassword'].errors?.required">
                    Old Password is required
                </div>
                <div *ngIf="resetPasswordForm.controls['oldPassword'].errors?.maxlength">
                    Max 15 characters are allowed
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">New Password*</label>
            <div class="input-group">
                <input [type]="isNewTextTypeInput ? 'text' : 'password'" class="form-control py-3" aria-describedby="passwordNote"
                    id="password" placeholder="New Password"
                formControlName="password" (keyup)="clearErrorMessage()">
                <div class="input-group-append">
                    <a (click)="isNewTextTypeInput = !isNewTextTypeInput" class="input-group-text" id="basic-addon2"
                        role="button">{{isNewTextTypeInput ? 'Hide' :
                        'show'}}</a>
                </div>
            </div>
          
            
            <div *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="resetPasswordForm.controls['password'].errors?.required">
                    New Password is required
                </div>
                <div *ngIf="resetPasswordForm.controls['password'].errors?.pattern">
                    <li>Password Must be at least 8 characters in length.</li>
                    <li>At least one uppercase and one lowercase letters.</li>
                    <li>At least one number.</li>
                    <li>At least one special characters. special characters must contain only (!@#$%^&*(),.;)</li>
                    <li>Max 15 characters are allowed</li>
                </div>
            </div>
            <div class="form-text text-danger"
                *ngIf="resetPasswordForm.controls['password'].dirty && submitted && resetPasswordForm.hasError('newPasswordMatchWithOldPassword') && !resetPasswordForm.controls['password'].errors?.required && !resetPasswordForm.controls['password'].errors?.pattern">
                New Password cannot be same as old password
            </div>
            <small id="passwordNote" class="form-text text-muted"
                *ngIf="!resetPasswordForm.controls['password'].errors?.pattern">
                <li>Password Must be at least 8 characters in length.</li>
                <li>At least one uppercase and one lowercase letters.</li>
                <li>At least one number.</li>
                <li>At least one special characters. special characters must contain only (!@#$%^&*(),.;)</li>
                <li>Max 15 characters are allowed</li>
            </small>
        </div>
        <div class="form-group mt-3">
            <label for="confirmPassword">Confirm Password*</label>
            <div class="input-group">
                <input [type]="isTextTypeInput ? 'text' : 'password'" class="form-control py-3" id="confirmPassword"
                    placeholder="Confirm Password"
                    formControlName="confirmPassword" (keyup)="clearErrorMessage()">
                <div class="input-group-append">
                    <a class="input-group-text" id="basic-addon2" role="button"
                        (click)="isTextTypeInput = !isTextTypeInput">{{isTextTypeInput ? 'Hide' :
                        'show'}}</a>
                </div>
            </div>
            <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && (resetPasswordForm.controls['confirmPassword'].dirty || resetPasswordForm.controls['confirmPassword'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="resetPasswordForm.controls['confirmPassword'].errors?.required">
                    Confirm Password is required
                </div>
            </div>
            <div class="form-text text-danger"
                *ngIf="resetPasswordForm.controls['confirmPassword'].dirty && submitted && resetPasswordForm.hasError('confirmPasswordsMisMatched') && !resetPasswordForm.controls['confirmPassword'].errors?.required">
                Confirm Password do not match
            </div>
        </div>
        <button type="submit" class="btn btn-primary sub-btn text-uppercase d-block w-100 py-4" (click)="submitted = true">{{!isSetPassword ? 'Reset' : 'Set'}} Password</button>
    </form>

</div>