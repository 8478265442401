import { Component } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  online$: Observable<boolean>;

  constructor() {
  this.online$ = merge(
   of(navigator.onLine),
   fromEvent(window, 'online').pipe(mapTo(true)),
   fromEvent(window, 'offline').pipe(mapTo(false))
  );
  }
}
