import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DeviceMakePagination,AddEditDeviceMakeResModel,AddEditDeviceMakeModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DeviceMakeService {
  deviceMakeUrl = `${environment.baseUrl}/device-make`;

  constructor(private http: HttpClient) { }

  getDeviceMake(query: string): Observable<DeviceMakePagination> {
    return this.http.get<DeviceMakePagination>(`${this.deviceMakeUrl}${query}`);
  }

  addDeviceMake(deviceMake: AddEditDeviceMakeModel): Observable<AddEditDeviceMakeResModel> {
    return this.http.post<AddEditDeviceMakeResModel>(`${this.deviceMakeUrl}/add-device-make`, deviceMake);
  }

  editDeviceMake(deviceMake: AddEditDeviceMakeModel,deviceMakeId:string): Observable<AddEditDeviceMakeResModel> {
    return this.http.patch<AddEditDeviceMakeResModel>(`${this.deviceMakeUrl}/edit-device-make/${deviceMakeId}`, deviceMake);
  }

  deleteDeviceMake(deviceMakeId:string): Observable<AddEditDeviceMakeResModel> {
    return this.http.delete<AddEditDeviceMakeResModel>(`${this.deviceMakeUrl}/delete-device-make/${deviceMakeId}`);
  }
}
