import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TabName } from 'src/app/core/constants';
import { UserService } from 'src/app/core/services/user.service';

enum ErrorType {
  INVALID_LOGIN = 'invalid_login',
  CONNECTION_ERROR = 'connection_error',
  NO_ERROR = 'no_error'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error = ErrorType.NO_ERROR;
  errorType = ErrorType;
  submitted = false;
  isTextTypeInput: boolean = false;
  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, private titleService: Title, private spinner: NgxSpinnerService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})')]],
      password: ['', [Validators.required, Validators.maxLength(15)]]
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(TabName.login);
  }

  login() {
    this.spinner.show();
    this.userService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe((res) => {
      this.spinner.hide();
      sessionStorage.setItem('token', res.token);
      this.getUser();
    }, error => {
        this.spinner.hide();
      if (error.statusText === 'Unknown Error') {
        this.error = ErrorType.CONNECTION_ERROR;
      } else {
        // set error to invalid login
        this.error = ErrorType.INVALID_LOGIN;
      }
    })
  }

  getUser() {
    zip(this.userService.getUser(), this.userService.getUserPermission())
      .subscribe(res => {
        sessionStorage.setItem('loggedIn', 'true');
        sessionStorage.setItem('user', JSON.stringify(res[0].user[0]));
        sessionStorage.setItem('permission', JSON.stringify(res[1].data));
        this.userService.defaultLandingPage();
      }, err => {
        sessionStorage.clear();
      })
  }

  clearErrorMessage() {
    this.submitted = false;
    this.error = ErrorType.NO_ERROR;
  }

}
