import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DeviceTypePagination, AddEditDeviceTypeModel, AddEditDeviceTypeResModel, DeviceTypeAssociationModel, DeviceTypeAssociationPagination, GenericResponseModel } from '../models';


@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {
  deviceTypeUrl = `${environment.baseUrl}/device-type`;
  deviceTypeAssociationUrl = `${environment.baseUrl}/device-type-association`;

  constructor(private http: HttpClient) { }

  getDeviceTypes(query: string): Observable<DeviceTypePagination> {
    return this.http.get<DeviceTypePagination>(`${this.deviceTypeUrl}${query}`);
  }

  addDeviceType(deviceType: AddEditDeviceTypeModel): Observable<AddEditDeviceTypeResModel> {
    return this.http.post<AddEditDeviceTypeResModel>(`${this.deviceTypeUrl}/add-device-type`, deviceType);
  }

  editDeviceType(deviceType: AddEditDeviceTypeModel, deviceTypeId: string): Observable<AddEditDeviceTypeResModel> {
    return this.http.patch<AddEditDeviceTypeResModel>(`${this.deviceTypeUrl}/edit-device-type/${deviceTypeId}`, deviceType);
  }

  deleteDeviceType(deviceTypeId: string): Observable<AddEditDeviceTypeResModel> {
    return this.http.delete<AddEditDeviceTypeResModel>(`${this.deviceTypeUrl}/delete-device-type/${deviceTypeId}`);
  }

  getDeviceTypeAssociation(query: string): Observable<DeviceTypeAssociationPagination> {
    return this.http.get<DeviceTypeAssociationPagination>(`${this.deviceTypeAssociationUrl}${query}`);
  }

  getDeviceTypeAssociationById(id: string): Observable<DeviceTypeAssociationPagination> {
    return this.http.get<DeviceTypeAssociationPagination>(`${this.deviceTypeAssociationUrl}/${id}`);
  }

  deleteDeviceTypeAssociation(id: string): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${this.deviceTypeAssociationUrl}/${id}`);
  }

  updateAssociationStatus(id: string, status: number): Observable<GenericResponseModel> {
    return this.http.patch<GenericResponseModel>(`${this.deviceTypeAssociationUrl}/update-status/${id}`, { status });
  }

  addAssociation(data: Array<DeviceTypeAssociationModel>): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${this.deviceTypeAssociationUrl}`, { data });
  }

  updateAssociation(data: DeviceTypeAssociationModel, id: string): Observable<GenericResponseModel> {
    return this.http.patch<GenericResponseModel>(`${this.deviceTypeAssociationUrl}/${id}`, data);
  }

  getDeviceTypeByFacility(query: string): Observable<any> {
    return this.http.get<any>(`${this.deviceTypeUrl}/facility/device-type`);
  }

}
