<div class="logo-wrap py-4 text-center">
    <img class="logo" src="../../../assets/img/Logo.png" alt="Logo">
</div>
<div class="seprator"></div>
<div class="login-form mx-auto">
    <div class="d-flex flex-column text-center text-danger" *ngIf="error !== errorType.NO_ERROR" [ngSwitch]="error">
        <div class="message">
            <ng-container *ngSwitchCase="errorType.INVALID_LOGIN">Email or password is incorrect!</ng-container>
            <ng-container *ngSwitchCase="errorType.CONNECTION_ERROR">Connection Error</ng-container>
        </div>
    </div>
    <h4 class="mb-3 text-center">Login to the Portal</h4>
    <p class="text-center">For the purpose of sign in, your details are required.</p>
    <div class="border"></div>
    <form [formGroup]="loginForm" (ngSubmit)="loginForm.valid && login()">
        <div class="form-group">
            <label for="email">Email address*</label>
            <input type="email" placeholder="Email" class="form-control py-3" id="email" formControlName="email" (keyup)="clearErrorMessage()">
            <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="loginForm.controls['email'].errors?.required">
                    Email is required
                </div>
                <div *ngIf="loginForm.controls['email'].errors?.pattern">
                    Email is invalid
                </div>
                <div *ngIf="loginForm.controls['email'].errors?.maxlength && !loginForm.controls['email'].errors?.pattern">
                    Max 100 characters are allowed
                </div>
            </div>
        </div>
        <div class="form-group mt-3">
            <label for="password">Password*</label>
            <div class="input-group position-relative">
                <input [type]="isTextTypeInput ? 'text' : 'password'" class="form-control py-3" id="password"
                    formControlName="password" (keyup)="clearErrorMessage()" placeholder="Password">
                <div class="input-group-append">
                    <a class="input-group-text" id="basic-addon2" role="button"
                        (click)="isTextTypeInput = !isTextTypeInput">{{isTextTypeInput ? 'Hide' :
                        'show'}}</a>
                </div>
            </div>
    
            <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="loginForm.controls['password'].errors?.required">
                    Password is required
                </div>
                <div *ngIf="loginForm.controls['password'].errors?.maxlength">
                    Max 15 characters are allowed
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary sub-btn text-uppercase d-block w-100 py-4" (click)="submitted = true">Login</button>
        <a routerLink="/forgot-password" class="frgt-text text-center d-block">Forgot Password</a>
    </form>
</div>
