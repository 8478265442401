import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FailureReasonPagination,AddEditFailureReasonResModel,AddEditFailureReasonModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FailureReasonService {
  failureReasonUrl = `${environment.baseUrl}/failure-reason`;
  constructor(private http: HttpClient) { }

  getFailureReasons(query: string): Observable<FailureReasonPagination> {
    return this.http.get<FailureReasonPagination>(`${this.failureReasonUrl}${query}`);
  }

  addFailureReason(failureReason: AddEditFailureReasonModel): Observable<AddEditFailureReasonResModel> {
    return this.http.post<AddEditFailureReasonResModel>(`${this.failureReasonUrl}/add-failure-reason`, failureReason);
  }

  editFailureReason(failureReason: AddEditFailureReasonModel,failureReasonId:string): Observable<AddEditFailureReasonResModel> {
    return this.http.patch<AddEditFailureReasonResModel>(`${this.failureReasonUrl}/edit-failure-reason/${failureReasonId}`, failureReason);
  }

  deleteFailureReason(failureReasonId:string): Observable<AddEditFailureReasonResModel> {
    return this.http.delete<AddEditFailureReasonResModel>(`${this.failureReasonUrl}/delete-failure-reason/${failureReasonId}`);
  }
}
