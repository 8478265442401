import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TabName } from 'src/app/core/constants';
import { UserService } from 'src/app/core/services/user.service';

enum ErrorMessage {
  REQUEST_FAILED = 'Failed to send request'
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  message: string = '';
  successMsg:boolean=false;
  subscriptions: Subscription = new Subscription();
  submitted = false;
  constructor(private fb: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService,
    private titleService: Title, private toaster: ToastrService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})')]]
    })
  }

  ngOnInit(): void {
    this.titleService.setTitle(TabName.forgotPassword);
  }

  forgotPassword() {
    this.spinner.show();
    this.subscriptions.add(
      this.userService.forgotPassword(this.forgotPasswordForm.value.email).subscribe((res) => {
        this.spinner.hide();
        this.toaster.success(res.msg, 'Success', {
          timeOut: 3000,
        })
      }, ex => {
          this.spinner.hide();
          console.error('error sending forgot password request');
          if (ex.error && ex.error.errors && ex.error.errors.length > 0) {
            this.message = ex.error.errors[0].msg;
          } else if (ex.error && ex.error.msg) {
            this.message = ex.error.msg;
          } else {
            console.error(ex.message);
            this.message = ex.msg ? ex.msg : ErrorMessage.REQUEST_FAILED;
          }
      })
    )
  }

  clearErrorMessage() {
    this.submitted = false;
    this.message = '';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
