import { FormGroup } from "@angular/forms";

export function confirmPasswordValidator(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
        let password = group.controls[passwordKey];
        let confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
            return {
                confirmPasswordsMisMatched: true
            };
        }
        return {}
    }
}

export function newPasswordValidator(oldPasswordKey: string, newPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
        let oldPassword = group.controls[oldPasswordKey];
        let newPassword = group.controls[newPasswordKey];

        if (oldPassword.value === newPassword.value) {
            return {
                newPasswordMatchWithOldPassword: true
            };
        }
        return {}
    }
}