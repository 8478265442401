import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        PhoneMaskDirective,
        ConfirmDialogComponent,
        FormatPhonePipe,
        NgSelectModule
    ],
    declarations: [
        PhoneMaskDirective,
        ConfirmDialogComponent,
        FormatPhonePipe
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
    providers: [DatePipe]
})
export class SharedModule { }