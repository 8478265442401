import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../services';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private userService: UserService,
        private toaster: ToastrService
    ) { }

    timeout: number = 20000; // default timeout to 20 seconds

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            timeout(this.timeout),
            catchError(response => {

                if (response instanceof HttpErrorResponse) {
                    try {
                        if (req.body && req.body.skipIntercepter) {
                            req.params.delete('skipIntercepter');
                            return next.handle(req);
                        }
                        console.error('response httpErrorRessponse ', response);
                        // unauthorize access => redirect to login page
                        if (response.status === 401) {
                            if (this.userService.isLoggedIn()) {
                                this.userService.logout().subscribe(res => {
                                    sessionStorage.clear();
                                })
                            }

                            this.router.navigate(['/login']);
                        } 
                        if (response.status === 403) {
                            this.toaster.error(response.error.msg, 'Error', { timeOut: 3000 });
                        }
                    } catch (e) {
                        console.error(response.error);
                    }
                }
                return throwError(response);
            })
        );
    }
}
