<div class="sucessIcon" *ngIf="data?.successIcon">
    <div>
        <i class="fa fa-check"></i>
    </div>
</div>
<div class="modal-header justify-content-center pt-4" style="border-bottom: none;">
    <h4 class="modal-title" id="modal-basic-title">{{data?.title}}</h4>
</div>
<div class="modal-body  justify-content-center text-center" *ngIf="data?.message">
    {{data?.message}}
</div>
<div class="modal-footer justify-content-center pt-0 pb-4" style="border-top:none">
    <button type="button" class="btn btn-outline-dark btn-cancel form-btn" aria-label="Close" (click)="close(false)" *ngIf="!data?.hideCancelButton">
        Cancel
    </button>
    <button type="button" class="btn btn-outline-dark form-btn" (click)="close(true)">{{data?.confirmButtonText}}</button>
</div>