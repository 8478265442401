import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InventoryPagination,AddEditInventoryResModel,AddEditInventoryModel,PendingInspectionListPagination } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  inventoryUrl = `${environment.baseUrl}/inventory`;

  constructor(private http: HttpClient) { }

  getInventory(query: string): Observable<InventoryPagination> {
    return this.http.get<InventoryPagination>(`${this.inventoryUrl}${query}`);
  }

  addInventory(inventoryData: AddEditInventoryModel): Observable<AddEditInventoryResModel> {
    return this.http.post<AddEditInventoryResModel>(`${this.inventoryUrl}/add-inventory`, inventoryData);
  }

  editInventory(inventoryData: AddEditInventoryModel,inventoryId:string): Observable<AddEditInventoryResModel> {
    return this.http.patch<AddEditInventoryResModel>(`${this.inventoryUrl}/edit-inventory/${inventoryId}`, inventoryData);
  }

  deleteInventory(inventoryId:string): Observable<AddEditInventoryResModel> {
    return this.http.delete<AddEditInventoryResModel>(`${this.inventoryUrl}/delete-inventory/${inventoryId}`);
  }

  uploadInventory(formData: any): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
    }
    return this.http.post<any>(`${this.inventoryUrl}/bulk-upload-inventory`, formData);
  }

  getPendingInspections(query:string): Observable<PendingInspectionListPagination>{
    return this.http.get<PendingInspectionListPagination>(`${this.inventoryUrl}${query}`);
  }

  getInspectionDevices(inspectionId: string, inventoriesId: string, query: string): Observable<any> {
    return this.http.get<any>(`${this.inventoryUrl}/${inspectionId}/conduct-inventory${query}`);
  }

}
