import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPhone' })
export class FormatPhonePipe implements PipeTransform {
    transform(phoneNumber: string): string {
        if (phoneNumber) {
            let unMaskphone = phoneNumber.replace(/[()-]/g, '');
            unMaskphone = phoneNumber.replace(/\s/g, '');
            phoneNumber = unMaskphone.substr(0, 10);
            let maskPhone = '(';
            const phone = phoneNumber.split('');
            for (let i = 0; i < phone.length; i++) {
                if (i === 3) {
                    maskPhone = maskPhone + ') ';
                }
                if (i === 6) {
                    maskPhone = maskPhone + '-';
                }
                maskPhone = maskPhone + phone[i];
            }
            return maskPhone;
        }
        return phoneNumber;
    }
}
