import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InspectionDatas } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  inspectionUrl = `${environment.baseUrl}/inspection`;
  selectedDateSubscription = new BehaviorSubject(new Date());
  selectedDate = new Date();
  constructor(private http: HttpClient) { }

  getInspection(): Observable<InspectionDatas> {
    return this.http.get<InspectionDatas>(`${this.inspectionUrl}/`);
  }

  getInspectionPeriod(): Observable<InspectionDatas> {
    return this.http.get<InspectionDatas>(`${this.inspectionUrl}/period`);
  }

  createInspection(data: any): Observable<any> {
    return this.http.post<any>(`${this.inspectionUrl}/schedule-inspection`, data);
  }

  editInspection(data: any, id: string): Observable<any> {
    return this.http.patch<any>(`${this.inspectionUrl}/schedule-inspection/${id}`, data);
  }

  getScheduledInspections(query: string): Observable<any> {
    return this.http.get<any>(`${this.inspectionUrl}/schedule-inspection${query}`);
  }

  deleteInspection(id: string): Observable<any> {
    return this.http.delete<any>(`${this.inspectionUrl}/schedule-inspection/${id}`);
  }

  getInspectionStats(id: string): Observable<any> {
    return this.http.get<any>(`${this.inspectionUrl}/get-inspection-devices-stats/${id}`);
  }
  getAnswerForForm(inspectionId: any, inventoryId: string, questionId: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/inspection-form/${inspectionId}/get-question-answer/${inventoryId}/question-id/${questionId}`);
  }

  getInspectionDevices(inspectionId: string, query: string): Observable<any> {
    return this.http.get<any>(`${this.inspectionUrl}/completed-inspection-devices/${inspectionId}/${query}`);
  }

  moveToPrevCategory(data: any): Observable<any> {
    return this.http.post<any>(`${this.inspectionUrl}/move-to-prev-category`, data);
  }
}
