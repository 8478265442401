import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { confirmPasswordValidator, newPasswordValidator } from '../../core/validators/password.validator';
import { UserService } from 'src/app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TabName } from 'src/app/core/constants';
import { Title } from '@angular/platform-browser';

enum ErrorMessage {
  REQUEST_FAILED = 'Failed to send request'
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;
  message: string = '';
  subscriptions: Subscription = new Subscription();
  submitted = false;
  token = '';
  isResetPassword: boolean = true;
  isSetPassword: boolean = false;
  currentUrl: string = '';
  isTextTypeInput: boolean = false;
  isNewTextTypeInput: boolean = false;
  isOldTextTypeInput: boolean = false;
  isLinkExpired = true;
  isLinkVerifiedByAPI = false;
  constructor(private fb: FormBuilder, private userService: UserService, private activatedRoute: ActivatedRoute,
    private router: Router, private toaster: ToastrService, private spinner: NgxSpinnerService, private titleService: Title) {
    this.currentUrl = this.router.url;
    if (this.currentUrl.includes('change-password')) {
      this.isResetPassword = false;
    }
    if (this.currentUrl.includes('create-password')) {
      this.isSetPassword = true;
    }
    if (!this.isResetPassword) {
      this.resetPasswordForm = this.fb.group({
        oldPassword: ['', [Validators.required, Validators.maxLength(15)]],
        password: ['', [Validators.required, Validators.pattern('(?=^.{8,15}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)(?=.*[!@#$%^&*(),.;])[0-9a-zA-Z!@#$%^&*(),.;]*$')]],
        confirmPassword: ['', [Validators.required]]
      }, { validator: [confirmPasswordValidator('password', 'confirmPassword'), newPasswordValidator('oldPassword', 'password')] });
    } else {
      this.resetPasswordForm = this.fb.group({
        password: ['', [Validators.required, Validators.pattern('(?=^.{8,15}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)(?=.*[!@#$%^&*(),.;])[0-9a-zA-Z!@#$%^&*(),;]*$')]],
        confirmPassword: ['', [Validators.required]]
      }, { validator: confirmPasswordValidator('password', 'confirmPassword') });
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.isResetPassword&&!this.isSetPassword ? TabName.resetPassword : (this.isSetPassword?TabName.setPassword:TabName.changePassword));
    this.token = this.activatedRoute.snapshot.params['token'];
    if (this.isResetPassword) {
      this.spinner.show();
      this.userService.resetPasswordVerify(this.token).subscribe(res => {
        this.isLinkExpired = false
        this.isLinkVerifiedByAPI = true;
        this.spinner.hide();
      }, err => {
          this.spinner.hide();
          this.isLinkExpired = true;
          this.isLinkVerifiedByAPI = true;
      })
    }
  }

  resetPassword() {
    this.spinner.show();
    this.subscriptions.add(
      this.userService.resetPassword(this.token, this.resetPasswordForm.value.password, this.resetPasswordForm.value.confirmPassword).subscribe((res) => {
        this.spinner.hide();
        this.submitted = false;
        this.toaster.success('Password Reset Successfully', 'Success', {
          timeOut: 3000,
        })
        this.router.navigate(['/login']);
      }, ex => {
          this.spinner.hide();
          console.error('error sending forgot password request');
          if (ex.error && ex.error.errors && ex.error.errors.length > 0) {
            this.message = ex.error.errors[0].msg;
          } else if (ex.error && ex.error.msg) {
            this.message = ex.error.msg;
          } else {
            console.error(ex.message);
            this.message = ex.msg ? ex.msg : ErrorMessage.REQUEST_FAILED;
          }
      })
    )
  }

  changePassword() {
    this.subscriptions.add(
      this.userService.changePassword(this.resetPasswordForm.value.oldPassword, this.resetPasswordForm.value.password, this.resetPasswordForm.value.confirmPassword).subscribe((res) => {
        this.submitted = false;
        this.resetPasswordForm.reset();
        this.toaster.success('Password Reset Successfully', 'Success', {
          timeOut: 3000,
        })
      }, ex => {
        console.error('error updating password request');
        if (ex.error && ex.error.errors && ex.error.errors.length > 0) {
          this.message = ex.error.errors[0].msg;
        } else if (ex.error && ex.error.msg) {
          this.message = ex.error.msg;
        } else {
          console.error(ex.message);
          this.message = ex.msg ? ex.msg : ErrorMessage.REQUEST_FAILED;
        }
      })
    )
  }

  updatePassword() {
    this.isResetPassword ? this.resetPassword() : this.changePassword();
  }

  clearErrorMessage() {
    this.message = '';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
