export const ACLPERMISSION = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    VIEW: 'view'
};

export const TabName = {
    login: "Login",
    forgotPassword: "Forgot Password",
    resetPassword: "Reset Password",
    changePassword: "Change Password",
    setPassword: "Set Password",
    dashboard: "Dashboard",
    user: "Manage Users - List",
    userAdd: "Manage Users - Add",
    userEdit: "Manage Users - Edit",
    userPermission: "Manage Users - Permission",
    facilities: "Manage Facilities - List",
    facilitiesAdd: "Manage Facilities - Add",
    facilitiesEdit: "Manage Facilities - Edit",
    fieldsAndAssociations: "Manage Fields And Associations",
    inspectionForm: "Device Type Inspection Forms",
    inventory: "Manage Inventory - List",
    inspectionScheduling: "Manage Inspection Schedule",
    reports: "Reports",
    epType: "EP Type List",
    epTypeAdd: "Add New EP Type",
    epTypeEdit: "Edit EP Type",
    deviceType:"Manage Device Type",
    failureReason:"Manage Failure Reason",
    deviceMake:"Manage Device Make",
    inspectionFormAdd:"Device Type Inspection Form - Add",
    inspectionFormEdit:"Device Type Inspection Form - Add",
    inventoryAdd:"Manage Inventory - Add",
    inventoryEdit:"Manage Inventory - Edit",
    deviceTypeAssociation: 'Device Type Associations - List',
    deviceTypeAssociationAdd: 'Device Type Associations - Add',
    deviceTypeAssociationEdit: 'Device Type Associations - Edit',
    userReports: "Manage User Reports - List",
};

export const ACLMODULENAME = {
    dashboard: "dashboard",
    user: "user",
    user_list: "user_list",
    facilities: "facilities",
    fieldsAndAssociations: "fieldsAndAssociations",
    ep_types: "ep_types",
    device_types: "device_types",
    device_types_associations: "device_types_associations",
    failure_reason: "failure_reason",
    noninspectionreason: "noninspectionreason",
    device_make: "device_make",
    inspectionForm: "inspectionForm",
    inventory: "inventory",
    inspectionScheduling: "inspectionScheduling",
    reports: "reports",
    userReports: "userreports",
}

export const SidebarImagePath = {
    dashboard: "dashboard",
    user: "user",
    user_list: "user_list",
    facilities: "facility-sidebar",
    fieldsAndAssociations: "manage-fields",
    inspectionForm: "inspection-forms",
    inventory: "manage-inventory",
    inspectionScheduling: "inspection-scheduling",
    reports: "reports",
    companyProfile: "badge_Icon",
    userreports:"user-reports"
}
