import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EpTypePagination,AddEditEpTypeResModel,AddEditEpTypeModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class EpTypeService {
  epTypeUrl = `${environment.baseUrl}/ep-type`;

  constructor(private http: HttpClient) { }

  getEpType(query: string): Observable<EpTypePagination> {
    return this.http.get<EpTypePagination>(`${this.epTypeUrl}${query}`);
  }

  addEpType(epType: AddEditEpTypeModel): Observable<AddEditEpTypeResModel> {
    return this.http.post<AddEditEpTypeResModel>(`${this.epTypeUrl}/add-ep-type`, epType);
  }

  editEpType(epType: AddEditEpTypeModel,epTypeId:string): Observable<AddEditEpTypeResModel> {
    return this.http.patch<AddEditEpTypeResModel>(`${this.epTypeUrl}/edit-ep-type/${epTypeId}`, epType);
  }

  deleteEpType(epTypeId:string): Observable<AddEditEpTypeResModel> {
    return this.http.delete<AddEditEpTypeResModel>(`${this.epTypeUrl}/delete-ep-type/${epTypeId}`);
  }

  getEpTypeByFacility(query:string): Observable<any> {
    return this.http.get<any>(`${this.epTypeUrl}/facility/ep-type`);
  }
}
