import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() data: {
    title: string;
    message: string;
    confirmButtonText: string;
    hideCancelButton: boolean;
    successIcon: boolean;
  } | undefined;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close(result: boolean) {
    this.activeModal.close(result);
  }

}
