<div class="logo-wrap py-4 text-center">
    <img class="logo" src="../../../assets/img/Logo.png" alt="Logo">
</div>
<div class="seprator"></div>
<div class="login-form mx-auto">
    <div class="d-flex flex-column alert" *ngIf="message.length">
        <div class="form-text text-center" [ngClass]="successMsg ? 'text-success' : 'text-danger'">
            <ng-container>{{message}}</ng-container>
        </div>
    </div>
    <h4 class="mb-3 text-center">Forgot Password?</h4>
    <p class="text-center mx-auto">Enter the email associated with your account  and we’ll send an email 
        with instructions to reset your password.</p>
    <div class="border"></div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordForm.valid && forgotPassword()">
        <div class="form-group">
            <label for="email">Registered Email Address*</label>
            <input type="email" class="form-control py-3" id="email" formControlName="email" (keyup)="clearErrorMessage()" placeholder="Email">
            <div *ngIf="forgotPasswordForm.controls['email'].invalid && (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched || submitted)"
                class="form-text text-danger">
                <div *ngIf="forgotPasswordForm.controls['email'].errors?.required">
                    Email is required
                </div>
                <div *ngIf="forgotPasswordForm.controls['email'].errors?.pattern">
                    Email is invalid
                </div>
                <div
                    *ngIf="forgotPasswordForm.controls['email'].errors?.maxlength && !forgotPasswordForm.controls['email'].errors?.pattern">
                    Max 100 characters are allowed
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary sub-btn text-uppercase d-block w-100 py-4" (click)="submitted = true">Send instruction</button>
        <a routerLink="/login" class="frgt-text text-center d-block">Already have an account?  Sign In</a>
    </form>
</div>
    