import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InspectionDatas } from '../models';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    inspectionUrl = `${environment.baseUrl}/inspection`;
    reportUrl = `${environment.baseUrl}/report`;
    adhocSelectedDate: Date = new Date();
    completedSelectedDate: Date = new Date();
    deficiencySelectedDate: Date = new Date();
    constructor(private http: HttpClient) { }

    getDeficiencyReport(query: string): Observable<any> {
        return this.http.get<any>(`${this.inspectionUrl}/schedule-inspection${query}`);
    }

    getreportDocument(query: string, inspection_id: string, ep_type_id: string): Observable<any> {
        let url = `${this.reportUrl}/documents/${inspection_id}`;
        if (ep_type_id) {
            url += `/${ep_type_id}`
        }
        return this.http.get<any>(`${url}${query}`);
    }

    getDeficiencyReportById(id: string): Observable<any> {
        return this.http.get<any>(`${this.reportUrl}/${id}/deficiency-report/completed`);
    }

    getInProgressDeficiencyReportById(id: string): Observable<any> {
        return this.http.get<any>(`${this.reportUrl}/${id}/deficiency-report/not_completed?in_progress_deficiency=true`);
    }

    getReportById(id: string, ep_type_id: string): Observable<any> {
        let url = `${this.reportUrl}/${id}/view-report`;
        if (ep_type_id) {
            url += `/${ep_type_id}`
        }
        return this.http.get<any>(url);
    }

    downloadPdf(url: string, name: string) {
        if (url.length) {
            const getServerName= url.split("/");
            const extension=getServerName[getServerName.length-1].split(".");
            const pdfName = `${name}.${extension[extension.length-1]}`;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
                saveAs(url, pdfName, { autoBom: true });
            };
            xhr.onerror = function () {
                console.error('could not download file');
            };
            xhr.send();
        }
    }

    getPdfUrl(id: string): Observable<any> {
        return this.http.get<any>(`${this.reportUrl}/${id}/deficiency-report-pdf/completed`);
    }

    getInprogressPdfUrl(id: string): Observable<any> {
        return this.http.get<any>(`${this.reportUrl}/${id}/deficiency-report-pdf/not_completed`);
    }

    getPdfUrlReport(id: string, epId: string): Observable<any> {
        let url = `${this.reportUrl}/${id}/generate-view-report`;
        if (epId) {
            url += `/${epId}`
        }
        return this.http.get<any>(`${url}`);
    }

    getReportList(query: string): Observable<any> {
        return this.http.get<any>(`${this.reportUrl}/list${query}`);
    }

    uploadDocument(formData: any, inspectionId: string, epId: string): Observable<any> {
        let url = `${this.reportUrl}/documents/${inspectionId}`;
        if (epId) {
            url += `/${epId}`
        }
        return this.http.post<any>(`${url}`, formData);
    }

    updateDocumentName(documentId: string, data: any) {
        return this.http.patch<any>(`${this.reportUrl}/documents/${documentId}`, data);
    }

    deleteDocumentName(documentId: string) {
        return this.http.delete<any>(`${this.reportUrl}/documents/${documentId}`);
    }

}
