import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: String = sessionStorage.getItem('token') || '';
        const arr: Array<string> = req.url.split('/');
        const baseUrl: string = arr[0] + '//' + arr[2];
        const apiList = [environment.baseUrl];
        if (apiList.includes(baseUrl)) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }

        return next.handle(req);
    }
}
