import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AddEditUserModel, GenericResponseModel, LoggedInUserModel, LoginResponseModel, UserModel, UserPaginatedModel, UserRoleModel, UserRoleResponseModel, UserPermissionModel, UserPermissionResponseModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userUrl = `${environment.baseUrl}/users`;

  constructor(private http: HttpClient, private router: Router) { }

  login(email: string, password: string): Observable<LoginResponseModel> {
    return this.http.post<LoginResponseModel>(`${environment.baseUrl}/auth/login`, { email, password });
  }

  forgotPassword(email: string): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.baseUrl}/auth/forgot-password`, { email });
  }

  resetPasswordVerify(token: string): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.baseUrl}/auth/reset-password-verify`, { token });
  }

  resetPassword(token: string, newPassword: string, confirmPassword: string): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.baseUrl}/auth/reset-password`, { token, newPassword, confirmPassword });
  }

  changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.baseUrl}/auth/change-password`, { oldPassword, newPassword, confirmPassword });
  }

  logout(): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.baseUrl}/auth/logout`, {});
  }

  isLoggedIn(): boolean {
    const loggedIn = sessionStorage.getItem('loggedIn');
    return !!loggedIn;
  }

  isAdmin(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return user && user.hasOwnProperty('role_name') && user.role_name === 'Admin' ? true : false;
  }

  getUsers(query: string): Observable<UserPaginatedModel> {
    return this.http.get<UserPaginatedModel>(`${this.userUrl}${query}`);
  }

  getUser(): Observable<LoggedInUserModel> {
    return this.http.get<LoggedInUserModel>(`${this.userUrl}/get-loggedin-user`);
  }

  updateUserStatus(userId: string, status: number): Observable<GenericResponseModel> {
    return this.http.patch<GenericResponseModel>(`${this.userUrl}/edit_user_status/${userId}`, { status });
  }

  deleteUser(userId: string): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${this.userUrl}/delete-user/${userId}`);
  }

  getRoles(): Observable<UserRoleResponseModel> {
    return this.http.get<UserRoleResponseModel>(`${environment.baseUrl}/role`);
  }

  addUser(user: AddEditUserModel): Observable<any> {
    return this.http.post<any>(`${this.userUrl}/add-user`, user);
  }

  updateUser(user: AddEditUserModel, userId: string): Observable<any> {
    return this.http.patch<any>(`${this.userUrl}/edit-user/${userId}`, user);
  }

  getCityAndState(zipcode: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/city-state/${zipcode}`);
  }

  getUserDashboardStats(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/dashboard`);
  }

  getDeviceStats(date: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/dashboard/get-device-statistic?date=${date}`);
  }

  getUserStats(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/dashboard/get_user_statistic`);
  }

  getFacilityStats(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/dashboard/get_facility_statistic`);
  }

  getDashboardName(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/dashboard/get_dashboard_name`);
  }

  getUserPermission(userId = ''): Observable<UserPermissionResponseModel> {
    let url = `${environment.baseUrl}/acl`;
    if (userId) url += `/${userId}`
    return this.http.get<UserPermissionResponseModel>(url);
  }

  updateUserPermission(userId: string, data: UserPermissionModel[]): Observable<GenericResponseModel> {
    return this.http.patch<GenericResponseModel>(`${environment.baseUrl}/acl/${userId}`, { data })
  }

  checkPermission(key: string, action: string) {
    let hasPemission = false;
    const userPermission = this.getPermission();
    for (let i = 0; i < userPermission.length; i++) {
      if (userPermission[i].key === key && userPermission[i].permission[action]) {
        hasPemission = true;
        break;
      } else if (userPermission[i].child_module) {
        for (let j = 0; j < userPermission[i].child_module.length; j++) {
          if (userPermission[i].child_module[j].key === key && userPermission[i].permission[action]) {
            hasPemission = true;
            break;
          }
        }
      }
    }
    return hasPemission;
  }

  defaultLandingPage() {
    const permission = this.getPermission();
    for (let i = 0; i < permission.length; i++) {
      if (permission[i].module_route) {
        this.router.navigate([`/${permission[i].module_route}`]);
        break;
      } else if (permission[i].child_module) {
        for (let j = 0; j < permission[i].child_module.length; j++) {
          if (permission[i].child_module[j].module_route) {
            this.router.navigate([`/${permission[i].child_module[j].module_route}`]);
            break;
          }
        }
      }
    }
  }

  getPermission() {
    return JSON.parse(sessionStorage.getItem('permission'));
  }

  getLoggedInUSer() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  getDatatableSettings() {
    return JSON.parse(sessionStorage.getItem('datatableSettings')) || {};
  }

  getEmailTemplate(query: string, id: any = null) {
    let url = `${environment.baseUrl}/email-template`
    if (id) {
      url += `/${id}`
    }
    return this.http.get<any>(`${url}${query}`);
  }

  updateEmailTemplate(id: string, body: { subject: string, description: string }) {
    return this.http.patch<any>(`${environment.baseUrl}/email-template/${id}`, body);
  }

  getCompanyProfile() {
    return this.http.get<any>(`${environment.baseUrl}/company-information/get-company-detail`);
  }

  updateCompanyProfile(id: string, body: any) {
    return this.http.patch<any>(`${environment.baseUrl}/company-information/${id}`, body);
  }
}
