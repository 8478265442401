import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DeviceTypeInspectionFormPagination,AddEditDeviceTypeInspectionFormResModel,AddEditDeviceTypeInspectionForm,getDeviceTypeForQuestionFormRes } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeInspectionFormService {
  deviceTypeInspectionFormUrl = `${environment.baseUrl}/inspection-form`;
  constructor(private http: HttpClient) { }

  getDeviceTypeInspectionForms(query: string): Observable<DeviceTypeInspectionFormPagination> {
    return this.http.get<DeviceTypeInspectionFormPagination>(`${this.deviceTypeInspectionFormUrl}${query}`);
  }

  addDeviceTypeInspectionForm(deviceTypeInspectionForm: AddEditDeviceTypeInspectionForm): Observable<AddEditDeviceTypeInspectionFormResModel> {
    return this.http.post<AddEditDeviceTypeInspectionFormResModel>(`${this.deviceTypeInspectionFormUrl}/add-device-type-inspection-form`, deviceTypeInspectionForm);
  }

  editDeviceTypeInspectionForm(deviceTypeInspectionForm: AddEditDeviceTypeInspectionForm,deviceTypeInspectionFormId:number): Observable<AddEditDeviceTypeInspectionFormResModel> {
    return this.http.patch<AddEditDeviceTypeInspectionFormResModel>(`${this.deviceTypeInspectionFormUrl}/edit-device-type-inspection-form/${deviceTypeInspectionFormId}`, deviceTypeInspectionForm);
  }

  deleteDeviceTypeInspectionForm(deviceTypeInspectionFormId:number): Observable<AddEditDeviceTypeInspectionFormResModel> {
    return this.http.delete<AddEditDeviceTypeInspectionFormResModel>(`${this.deviceTypeInspectionFormUrl}/delete-device-type-inspection-form/${deviceTypeInspectionFormId}`);
  }

  addQuestionForm(formData: any): Observable<any> {
    return this.http.post<any>(`${this.deviceTypeInspectionFormUrl}/save-question-form`, formData);
  }

  getAllQuestionForm(formId:number): Observable<any> {
    return this.http.get<any>(`${this.deviceTypeInspectionFormUrl}/${formId}/get-question-form`);
  }

  editQuestionForm(formData: any,formId:number): Observable<any> {
    return this.http.patch<any>(`${this.deviceTypeInspectionFormUrl}/edit-question-form/${formId}`, formData);
  }

  deleteQuestionForm(formId:number): Observable<any> {
    return this.http.delete<any>(`${this.deviceTypeInspectionFormUrl}/delete-question-form/${formId}`);
  }

  getDeviceTypeForQuestionForm(): Observable<getDeviceTypeForQuestionFormRes> {
    return this.http.get<getDeviceTypeForQuestionFormRes>(`${this.deviceTypeInspectionFormUrl}/device-type/question-form`);
  }

}
