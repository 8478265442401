import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { JSONInterceptor } from './json.interceptor';
import { JWTInterceptor } from './jwt.interceptor';

export * from './json.interceptor';
export * from './jwt.interceptor';

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JSONInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JWTInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true
    }
];
